"use client";
import {
  Badge,
  Box,
  Container,
  Flex,
  IconButton,
  Link,
  TabNav,
  Text,
} from "@radix-ui/themes";
import {useSession} from "next-auth/react";
import {usePathname, useRouter} from "next/navigation";
import {FaFrog, FaRegBell} from "react-icons/fa6";
import UserMenu from "./UserMenu";

//const Nav = async ({
//  children,
//}: {
//  children: React.ReactNode | React.ReactNode[];
//}) => {
type Props = {
  numNotifications: number;
};
const Nav = ({numNotifications}: Props) => {
  //const session = await getServerSession(options);
  const session = useSession()?.data as any;
  const pathname = usePathname();
  const router = useRouter();

  const activeTab = pathname.match(/^\/creators/)
    ? "creators"
    : pathname.match(/^\/create/)
      ? "create"
      : pathname.match(/^\/admin/)
        ? "admin"
        : pathname.match(/^\/notifications/)
          ? "notifications"
          : "translate";

  const loggedOutHome = pathname.match(/^\/$/) && !session;

  return (
    <div style={{isolation: "isolate"}}>
      <Box
        pt="1"
        px="5"
        position="fixed"
        width="100vw"
        top="0"
        height="2.6rem"
        style={{
          boxShadow: "0 1px var(--accent-5)",
          backgroundColor: "var(--accent-3)",
          opacity: 1.0,
          zIndex: 1,
        }}
      >
        <Container>
          <Flex justify="between" align="center" height="2.25rem">
            <Link href="/" className="text-black no-underline">
              <Flex gap="2" align="center">
                <FaFrog size="30px" />
                <Box display="inline-block">
                  {!!loggedOutHome || (
                    <Text size="4" weight="light">
                      Criblate
                    </Text>
                  )}
                </Box>
              </Flex>
            </Link>
            <Flex align="center" gap="6">
              {session && (
                <TabNav.Root className="shadow-none">
                  <TabNav.Link active={activeTab === "translate"} href="/">
                    Translate
                  </TabNav.Link>
                  {session && session.isCreator && (
                    <TabNav.Link active={activeTab === "create"} href="/create">
                      Create
                    </TabNav.Link>
                  )}
                  <TabNav.Link
                    active={activeTab === "creators"}
                    href="/creators"
                  >
                    Find creators
                  </TabNav.Link>
                  {session && session.isAdmin && (
                    <TabNav.Link active={activeTab === "admin"} href="/admin">
                      Admin
                    </TabNav.Link>
                  )}
                </TabNav.Root>
              )}

              {!!session && (
                <IconButton
                  variant="ghost"
                  size="3"
                  radius="full"
                  onClick={() => router.push("/notifications")}
                >
                  <Flex position="relative">
                    <FaRegBell size="1rem" />
                    {numNotifications > 0 && (
                      <Flex position="absolute" top="-0.5rem" right="-0.5rem">
                        <Badge
                          color="red"
                          variant="solid"
                          radius="full"
                          size="1"
                          className="p-[0px_4px] text-[11px]"
                        >
                          {numNotifications}
                        </Badge>
                      </Flex>
                    )}
                  </Flex>
                </IconButton>
              )}
              {session ? (
                <UserMenu session={session} />
              ) : (
                <Link href="/api/auth/signin">Sign in</Link>
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </div>
  );
};

export default Nav;
