"use client";
import {Avatar, Box, Button, Flex, Popover} from "@radix-ui/themes";
import {signOut} from "next-auth/react";
import {useState} from "react";
import Spinner from "./Spinner";

const UserMenu = ({session}: any) => {
  const [signingOut, setSigningOut] = useState(false);

  const doSignOut = async () => {
    setSigningOut(!signingOut);
    await signOut();
  };

  return (
    <div>
      <Popover.Root>
        <Popover.Trigger>
          {/* <Button variant="ghost" radius="full"> */}
          <div className="cursor-pointer">
            <Avatar
              size="2"
              src={session?.user?.image! + ""}
              alt={session?.user?.name!}
              fallback={(session?.user?.name + "")
                .split(/ /)
                .map((s) => s[0])
                .join("")}
              radius="full"
            ></Avatar>
          </div>
          {/* </Button> */}
        </Popover.Trigger>

        <Popover.Content>
          <Flex direction="column" gap="3" align="center" px="2">
            <Box>
              <Avatar
                size="3"
                src={session?.user?.image!}
                fallback="BE"
                radius="full"
              />
            </Box>
            <Box>{session?.user?.name}</Box>
            <Button onClick={doSignOut} disabled={signingOut}>
              <Flex px={signingOut ? "4" : "6"} gap="3" align="center">
                {signingOut && <Spinner size={5} />} Sign out
              </Flex>
            </Button>
          </Flex>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};

export default UserMenu;
